import React, { useState, useEffect, useContext } from "react";
import Footer from "../../../components/Footer";
import Header from "../../../components/Header";
import ZodiacDD from "../../../components/ZodiacDD";
import banner from "../../../staticAssets/Horoscope Bg.png";
import logo from "../../../staticAssets/Pisces.png";
import axios from "axios";
import { navigate } from "gatsby";
import { AuthContext } from "../../../context/Auth";
import { Helmet } from "react-helmet-async";
import { AnalyticsContext } from "../../../context/Analytics";

function Index({location}) {
  const [blogs, setblogs] = useState([]);
const { gtmPageViewEvent } = useContext(AnalyticsContext) || {
    gtmPageViewEvent: () => {},
  };
  const { user } = useContext(AuthContext) || {
    user: {},
  };
  useEffect(() => {
    const fn = async () => {
      let res = await axios.get(
        "https://www.bodhi.app/blog/wp-json/wp/v2/posts"
      );

      if (res.status == 200) {
        setblogs([...res.data]);
      }
    };
    gtmPageViewEvent(window.location.pathname, window.location.host);
fn();
  }, []);
  return (
    <div className="h-screen flex flex-col block hide-scroll-bar scrollbar-hide worksans w-screen overflow-x-clip">
      <Header />
      <Helmet>
        <link
          rel="canonical"
          href={"https://www.bodhi.app"+location?.pathname}
        />
      </Helmet>
      <div className="flex-1 overflow-y-auto flex flex-col">
        <main className="grow hide-scroll-bar scrollbar-hide">
          <div className=" w-full relative bg-[#FFEEEF] overflow-hidden">
            <img className="h-32 md:h-auto w-full object-fill" src={banner} />
            <div className="absolute top-0 w-full h-full flex justify-center items-center">
              <h1 className="text-center text-2xl md:text-4xl font-semibold text-white">
                Pisces Traits
              </h1>
            </div>
          </div>
          <div className="container mx-auto flex flex-col">
            <div className="flex flex-wrap my-2 ml-2">
              <a
                href="https://www.bodhi.app/"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Bodhi</h4>
              </a>
              
              <h4 className="mx-2 text-sm">/</h4>
              <a
                href="https://www.bodhi.app/zodiac-signs/"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Zodiac Signs</h4>
              </a>
              <h4 className="mx-2 text-sm">/</h4>
              <a
                href="https://www.bodhi.app/zodiac-signs/pisces"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Pisces </h4>
              </a>
              
              <h4 className="mx-2 text-sm">/</h4>
              <h4 className="text-sm">Pisces Traits</h4>
            </div>
            <div className="flex mt-10 items-center">
              <div>
                <img className="h-24 md:h-48" src={logo} />
              </div>
              <div className="flex-1 flex flex-col md:flex-row">
                <div className=" flex-1 flex flex-col">

                <h1 className="font-semibold text-xl md:text-4xl ml-4">Pisces Traits</h1>
                <h2 className="text-sm md:text-base ml-4">Feb 19 - Mar 20</h2>
                </div>
                <div className="">
                <ZodiacDD name={"Change Sunsign"} />
              </div>
              </div>
              
            </div>
            <div className="grid grid-cols-3 md:grid-cols-4 gap-2 md:gap-8 mt-10 text-[0.8rem] md:text-base mx-1">
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2 py-1" onClick={() => {
                    navigate("/zodiac-signs/pisces/pisces-woman");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Pisces Woman</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>

              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2" onClick={() => {
                    navigate("/zodiac-signs/pisces/pisces-health");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Pisces Health</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2" onClick={() => {
                    navigate("/zodiac-signs/pisces/pisces-career");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Pisces Career</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/pisces/pisces-love");
                  }}>
                  <h5 className="font-semibold text-black">Pisces Love</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/pisces/pisces-nature");
                  }}>
                  <h5 className="font-semibold text-black">Pisces Nature</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/pisces/pisces-man");
                  }}>
                  <h5 className="font-semibold text-black">Pisces Man</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/pisces/pisces-relationship");
                  }}>
                  <h5 className="font-semibold text-black">Pisces Relationship</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/pisces/pisces-traits");
                  }}>
                  <h5 className="font-semibold text-black">Pisces Traits</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/pisces/pisces-facts");
                  }}>
                  <h5 className="font-semibold text-black">Pisces Facts</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
            </div>
            <span className="my-4 mx-1 md:mx-0">
            <p className="font-bold text-orange-500 inline-block mr-2">Let's look at some of the positive and negative traits of Pisces:</p> 
                  Selfless, and Natural Healers Negative Traits: Moody,
                  Pessimistic, Lazy, Ideological, and Escapists.
                  <br />
                  <br />
                  Pisces Positive Traits Empathetic Pisces is the most
                  empathetic of the animals, with an innate ability for empathy
                  that is second to none. Pisces have a wonderfully wide range
                  of kindness that spans from a lack of empathy to a complete
                  sympathy with all the emotions of the other species they are
                  not close to.
                  <br />
                  <br />
                  Pisces are complex, and even in the most distant species, they
                  have a full spectrum of emotions that can be shared with
                  others. They have a keen capacity for empathy, and even with
                  those they barely see eye to eye with
                  <br />
                  <br />
                  <p className="playfair text-black text-xl md:text-3xl">Artistic</p>
                  <br />
                  Their artistic intelligence is their creativity, and they have
                  no limits. They are the most creative sign of the zodiac.
                  Their creativity is a direct reflection of the zodiac sign
                  they are in. They have no limitations, and they are always
                  aware of their artistic prowess. They have a unique viewpoint
                  on the world that allows them to notice things innovative and
                  creative.
                  <br />
                  <br />
                  They are knowledgeable in a subject at a very young age and
                  are talented in their respective fields.
                  <br />
                  <br />
                  <p className="playfair text-black text-xl md:text-3xl">Emotional</p><br/>
                  We all have a habit of jumping to conclusions and believing
                  the worst about the person we dislike. However, it is possible
                  to be more sympathetic and considerate towards others when we
                  see the signs of a threat. If they see tears, they will likely
                  cry. You can also hurt and hurt others if you are superficial.
                  They may not be able to see the depth of your feelings, but
                  you can break them with your actions.
                  <br />
                  <br />
                  <p className="playfair text-black text-xl md:text-3xl">Selfless</p><br/>
                  Pisces people are born under this sign that they are perceived
                  to be the most generous and selfless people, but they are not.
                  Pisces are very compassionate, kind, and caring. They do not
                  care about themselves. However, they are very selfish. They
                  will only care about the well-being of other people. Pisces is
                  the classical archetype of generosity. They show up to help
                  you out when you fall, and they are often the ones to remind
                  you to pay back a debt you forgot to pay.
                  <br />
                  <br />
                  <p className="playfair text-black text-xl md:text-3xl">Natural Healers</p><br/>
                  The ability to heal oneself from disease through a natural
                  phenomenon is referred to as Pisces (a Greek word for frog).
                  One who is a Pisces is said to be a "healer" or "healer" and
                  can be called a "healer of the healer" or a "healer of the
                  healer." ' Pisces is naturally healing. They can assist with
                  the various aspects of healing, such as removing wounds,
                  providing warmth, and balancing the mood. They have the power
                  to give Pisces the ability to heal any physical or emotional
                  trauma. They would use their powers to help people who, in the
                  words of an old Indian proverb, need help, but not in a way
                  that will serve them. How about that. They used their power to
                  help people they couldn't help themselves.
                  <br />
                  <br />
                  <p className="playfair text-black text-xl md:text-3xl">
                    Pisces Negative Traits
                  </p>
                  <br />
                  <p className="playfair text-black text-xl md:text-3xl">Moody</p><br/>
                  Pisces are lovely individuals, but they are also understood
                  for their attitude and negative trends. When they are grumpy,
                  it is best to give them some area. Half of the moment, this
                  watery sign is discouraged, and they do not know why.
                  <br />
                  <br />
                  <p className="playfair text-black text-xl md:text-3xl">Negative</p><br/>
                  When feeling down, Pisces feels every harmful opinion all at
                  once. They do not get over things quickly, which often
                  prolongs their healing time after a nasty adventure. During
                  that period, they are probably to evolve negatively.
                  <br />
                  <br />
                  <p className="playfair text-black text-xl md:text-3xl">Lazy</p><br/>
                  Pisces no longer lives in a world of endless opportunity. But
                  they often lack the discipline to avoid the temptation to work
                  their tails off. They usually go for the latest and greatest,
                  sometimes for their health. Pisces often drive themselves to
                  work excessively, which is often a bad idea. They withdraw
                  themselves emotionally when Pisces is unconvinced that he can
                  change the situation. Pisces wants to move on from the past.
                  Pisces are pessimistic, they feel that no matter how hard in
                  life, he tries things will never get any better.
                  <br />
                  <br />
                  <p className="playfair text-black text-xl md:text-3xl">Idealistic</p><br/>
                  Pisceans, because of their romantic nature, often find
                  themselves walking alone. These people are idealistic and tend
                  to be very passionate. They are also very reactive in nature
                  and are quick to react to any slight event that may happen in
                  their lives. They are very independent and have a lot of
                  confidence in their abilities.
                  <br />
                  <br />
                  <p className="playfair text-black text-xl md:text-3xl">Escapists</p><br/>
                  Some call Pisces an escapist. They are daydreamers and
                  escapists who get lost in their minds and imagination;
                  somehow, they feel alone. When you think you have them, they
                  elusively slip away from you.
                  <br />
                  <br />
                  Understanding the characteristics of Pisces will help you
                  build better relationships.
                  <br />
                  <br />
                  <p className="playfair text-black text-xl md:text-3xl">
                    Pisces Love And Relationships
                  </p>
                  <br />
                  Pisces in a relationship are very bravely romantic,
                  affectionate, intuitive, and wise. These people are those who
                  believe that their love life is like a fairy tale scenario.
                  They always dream about their future relationships. they are
                  very soft-hearted, that's the reason they fall in love quickly
                  and deeply. by their nature, They are earnest about their
                  relationships
                  <br />
                  <br />
                  Their happiness relies on their loved ones because they
                  believe in family love and are ready to do anything for them.
                  They are accommodating and easily connected to everyone. They
                  connect emotionally to others.
                  <br />
                  <br />
                  A Pisces put everything on the table as they feel all their
                  emotions deeply. When they are in love with Pisces, people
                  ensure that their partner is not goi g to face any difficulty.
                  They are always ready to help those people whom they love.
                  They keep nothing left unsaid. Frequently they have to walk
                  through fire and back for the person they love, and that sort
                  of love is something one will only experience once in a
                  lifetime.
                  <br />
                  <br />
                  <p className="playfair text-black text-xl md:text-3xl">
                    Pisces Friends And Family
                  </p>
                  <br />
                  Pisces are the type of people who will put their family and
                  friends before themselves. Their happiness relies on making
                  Pisces Happy. We agreed They have the heart of a soldier, and
                  when it comes to family and friends, they will do anything. A
                  Pisces will always be there in your corner, ready to defend
                  you. They always say what you need to hear to make it through
                  the day.
                  <br />
                  <br />
                  Pisces care more about family and friends because they have
                  minimal people and very few friends. Their empathetic nature
                  makes them like your own personal therapist during rough
                  times. They are that friend who will ask you what is wrong?
                  When no one else noticed. If you have Pisces best friends, you
                  don't need to worry about anything. They are with you no
                  matter how complex your situation is. Whatever you share with
                  them, they are open to taking any criticism of unfair
                  decisions.
                  <br />
                  <br />
                  <p className="playfair text-black text-xl md:text-3xl">
                    Pisces Career And Money
                  </p>
                  <br />
                  <p className="playfair text-black text-xl md:text-3xl">Pisces and Career</p><br/>
                  Understanding, gentle and affectionate, Pisces is one of the
                  most inspired and creative zodiac signs. This water sign with
                  a dreamy disposition finds it challenging to be on time. They
                  are not punctual about time, and yes, indeed, they are usually
                  not suited to a nine-to-five job. Still, they try to thrive in
                  an unstructured environment. they always want a career in
                  which they have various visions, imagination, and empathy are
                  essential. Want to know more about Pisces' Career.
                  <br />
                  <br />
                  <p className="playfair text-black text-xl md:text-3xl">Pisces and Money</p><br/>
                  Pisces people know how to handle money without wasting it on
                  unnecessary things, but they may often squander their cash on
                  impulsive buys. They believe in a return policy because this
                  tendency to buy something only to return later shows
                  everything about them. They can be pretty spontaneous when
                  shopping and prone to changing their minds later.
                  <br />
                  <br />
                  While they can be pretty helpful and charitable with their
                  money, they can be pretty gullible to spending all of it on
                  vices. This watery sign believes that time, money, and
                  relationship are the three most important things.
                  <br />
                  <br />
                  <p className="playfair text-black text-xl md:text-3xl">How To Attract Pisces?</p><br/>
                  To attract Pisces, you don't have to buy anything because they
                  are not impressed by money and success. They are often
                  attracted to authoritative people, but they must be equally
                  sensitive and caring.
                  <br />
                  <br />
                  there are some ways to tempt this watery sign:
                  <br />
                  <br />
                  <p className="font-bold text-orange-500 inline-block mr-2">
                    Be Yourself:
                  </p>{" "}
                  Under all circumstances, be yourself! You can start the
                  conversation to get to know them better and to avoid any
                  awkwardness in the future, if there is any. Always make eye
                  contact and be clear in your head about your ideas and how to
                  implement them, and also show interest in what they say.
                  <br />
                  <br />
                  <p className="font-bold text-orange-500 inline-block mr-2">
                    Be Spiritual:
                  </p>{" "}
                  they like spiritual things and something related to nature/go,
                  so talk to them about these things. It will increase their
                  interest in your talks. They admire soft-spoken people, so
                  it's good for you if you're soft by heart.
                  <br />
                  <br />
                  Now that you know more about the Pisces personality traits,
                  find out how you match with other Zodiac Signs.
                  <br />
                  <br />
                  Compatible Signs Pisces Should Consider Their soft-spoken,
                  gentle and caring nature makes the Pisces star sign very
                  engaging as a human being. The most consistent Pisces signs
                  are generally considered Scorpio, Cancer, and Capricorn.
                  <br />
                  <br />
            </span>
          </div>
          <div className="container mx-auto">
            <div className="flex justify-between mb-4 mx-2">
              <h1 className="text-xl md:text-3xl text-black playfair">
                Recent Blogs
              </h1>
              <a
                className="text-sm md:text-md text-orange-500 text-center font-semibold"
                href="https://www.bodhi.app/blog/"
              >
                See More
              </a>
            </div>
            <div className="relative">
                <div className="absolute top-0 right-0 w-0 md:w-36 fixed h-full bg-gradient-to-r from-transparent to-white"></div>
              <div className="flex overflow-x-scroll pb-4 mt-2 hide-scroll-bar scrollbar-hide">
                <div className="flex flex-nowrap ml-2">
                  {blogs.slice(0, 10).map((item, i) => {
                    return (
                      <a href={item.link} key={i} target="_blank">
                        <div className="flex flex-col h-80 w-52 justify-start items-start bg-gray-100 mr-10 mb-10 rounded-lg overflow-hidden hover:shadow-lg">
                          <img
                            className="object-cover w-52 h-52 w-auto bg-gray-100"
                            src={
                              item?.yoast_head_json?.og_image
                                ? item?.yoast_head_json?.og_image[0]?.url
                                : ""
                            }
                          />
                          <span className="mt-2 block mx-2 text-center">
                            {item?.yoast_head_json?.title ?? ""}
                          </span>
                        </div>
                      </a>
                    );
                  })}
                  {/* <div className="flex h-80 w-52 justify-center items-center "></div> */}
                </div>
              </div>
            </div>
          <button
            className="justify-center text-white fixed right-2 bottom-2 rounded-t-2xl but px-4 py-2 flex gap-1 shadow-xl"
            onClick={() => {
              navigate("/consultAstro");
            }}
          >
            {user.name !== undefined?`Consult Top Astrologers Now!`:`Login & get ${
              user?.currencyType == "usd" ? "$ 4" : "₹ 91"
            } in your account!`}
          </button>
          </div>
        </main>
        <Footer />
      </div>
    </div>
  );
}

export default Index;
